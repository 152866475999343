<template>
  <b-container class="overflow-auto" fluid>
    <!-- Publisher Interface controls -->
    <a @click="goBack" style="border: 1px black"><span class="fa fa-long-arrow-left"></span> Back</a>
    <h5 class="my-3">Showing Video Zones for publisher:
      <strong>{{ typeof (videozone.list[0]) === 'undefined' ? '' : videozone.list[0].PublisherName }}</strong>
    </h5>
    <a-row>
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-video-zones'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-videozones-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :sort-compare="sortCompare" :bordered="true" :current-page="currentPage" :fields="filteredFields" :filter="filter" :hover="true"
      :items="showVideozones" :per-page="perPage" :small="true" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection" :striped="true" :tbody-tr-class="rowClass" show-empty stacked="md"
      @filtered="onFiltered">
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleVideozoneStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Campaigns)="data">
        <div v-if="data.item.Campaigns.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="campaign.Id" v-for="campaign in campaigns">
                  {{
      data.item.Campaigns.includes(campaign.Id) ? `[${campaign.Id}] ${campaign.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Campaigns.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Remotertbzones)="data">
        <div v-if="data.item.Remotertbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in remotertbzones">
                  {{
                    data.item.Remotertbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Remotertbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Remotevideozones)="data">
        <div v-if="data.item.Remotevideozones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in remotevideozones">
                  {{
                    data.item.Remotevideozones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Remotevideozones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(AuctionType)="data">
        {{ data.item.AuctionType === 2 ? 'Second Price Plus' : 'First Price' }}
      </template>
      <template #cell(Secure)="data">
        {{ data.item.Secure === 1 ? '✔' : '' }}
      </template>
      <template #cell(Linearity)="data">
        {{ formatLinearity(data.item.Linearity) }}
      </template>
      <template #cell(Placement)="data">
        {{ formatPlacement(data.item.Placement) }}
      </template>
      <template #cell(PublisherRevShare)="data">
        <div class="items-center">{{ data.item.PublisherRevShare }}%</div>
      </template>
      <template #cell(Tmax)="data">
        {{ data.item.Tmax }} ms
      </template>
      <template #cell(actions)="data">
        <div class="flex">
          <b-button @click="showModal(data.item)" class="btn-info mr-1" size="sm">
            <i class="fa fa-info"></i>
          </b-button>
          <router-link class="add-button" @click.stop
            :to="{ name: 'videozone-update', params: { id: data.item.Id }, }">
            <b-button class="btn-info mr-1" size="sm" @click="editVideozone(data.item.Id)">
              <i class="fa fa-edit"></i>
            </b-button>
          </router-link>
          <b-button class="btn-info mr-1" size="sm" @click="copyVideozone(data.item.Id, data.item)">
            <i class="fa fa-copy"></i>
          </b-button>
        </div>
      </template>
      <template #cell(ImpressionTrackingMethod)="data">
        {{ impressionTrackingMethod[data.item.ImpressionTrackingMethod] }}
      </template>
    </b-table>
    <b-modal id="bv-modal-example" hide-footer size="lg" body-class="rtb-modal" class="rtb-modal">
      <template #modal-title>
        Video Zone Info
      </template>
      <div class="d-block" style="min-height: 500px">
        <div>
          <a-tabs default-active-key="1" class="mb-5">
            <a-tab-pane key="1" tab="URL Template">
              <div class="card">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  VAST Tag URL
                </div>
                <div class="card-body p-3">
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      <span>Copy URL</span>
                    </template>
                    <a-input-search :value="modal.zoneUrl" placeholder="input search text" @focus="selectUrl"
                      @change="$event.target.value = modal.zoneUrl" @search="copyUrl">
                      <template #enterButton>
                        <a-button @click="copyUrl(modal.zoneUrl)"><i class="fa fa-copy"></i></a-button>
                      </template>
                    </a-input-search>
                  </a-tooltip>
                </div>
              </div>
              <div>
                <p class="parameters-title">Parameters</p>
                <div class="parameter">
                  <p class="parameter-key">ip</p>
                  <p class="parameter-value">IP address of the visitor.</p>
                </div>
              </div>
              <div class="parameter">
                <p class="parameter-key">ua</p>
                <p class="parameter-value">User-Agent of the visitor. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">width</p>
                <p class="parameter-value">Video player width.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">height</p>
                <p class="parameter-value">Video player height.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">linearity</p>
                <p class="parameter-value">Type of an ad impression: 1 = Linear, 2 = Overlay.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">pos</p>
                <p class="parameter-value">Video position on screen: 0 = All, 1 = Above the fold, 3 = Below the fold, 4 =
                  Header, 5 = Footer, 6 = Sidebar, 7 = Fullscreen.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">placement</p>
                <p class="parameter-value">Video placement type: 1 = In-Stream, 2 = In-Banner, 3 = In-Article, 4 =
                  In-Feed, 5 = Interstitial/Slider/Floating.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">site_url</p>
                <p class="parameter-value">URL of the page where ads will be displayed. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_bundle</p>
                <p class="parameter-value">Application bundle. For example: "com.app.test" for Android apps and
                  "343200656" for iOS apps.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_name</p>
                <p class="parameter-value">Name of the application. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">app_store_url</p>
                <p class="parameter-value">Store URL of the application. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">bidfloor</p>
                <p class="parameter-value">Minimal CPM for the request.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">minduration</p>
                <p class="parameter-value">Minimal Video Ad duration in seconds.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">maxduration</p>
                <p class="parameter-value">Maximal Video Ad duration in seconds.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">startdelay</p>
                <p class="parameter-value">Start delay in seconds for pre-roll, mid-roll, or post-roll ad placements: >0 = Mid-Roll (value indicates start delay in second), 0 = Pre-Roll, -1 = Generic Mid-Roll, -2 = Generic Post-Roll</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">category</p>
                <p class="parameter-value">Advertising category according to IAB classification.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_type</p>
                <p class="parameter-value">The general type of device according to IAB classification.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_make</p>
                <p class="parameter-value">Device make (e.g., "Apple"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">device_model</p>
                <p class="parameter-value">Device model (e.g., "iPhone"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">os</p>
                <p class="parameter-value">Device operating system (e.g., "iOS"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">osv</p>
                <p class="parameter-value">Device operating system version (e.g., "17"). Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">lang</p>
                <p class="parameter-value">Language using ISO-639-1-alpha-2 (e.g., "en").</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">ifa</p>
                <p class="parameter-value">Identifier for Advertisers. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">paln</p>
                <p class="parameter-value">PALN. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">givn</p>
                <p class="parameter-value">GIVN. Must be URL encoded.</p>
              </div>
              <div class="parameter">
                <p class="parameter-key">cb</p>
                <p class="parameter-value">Cache buster: a uniquely generated character sequence for each link in order to prevent page caching.</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Publisher Reports">
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Date
                </div>
                <div class="card-body p-3">
                  <a-input-search :value="modal.reportsByDate" placeholder="" @focus="selectUrl"
                    @change="$event.target.value = modal.reportsByDate" @search="copyUrl">
                    <template #enterButton>
                      <a-button @click="copyUrl(modal.reportsByDate)"><i class="fa fa-copy"></i></a-button>
                    </template>
                  </a-input-search>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button v-if="publisherStatus === 0" class="btn btn-success px-5" @click="redirectToCreateVideozone()">Create
          Video Zone
        </button>
        <b-alert show variant="danger" v-else> Publisher is Deactivated</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneVideozone } from '@/api/display/videozone'
import { getPublishers } from '@/api/display/publisher'
import { getRemoteRTBZonesShort } from '@/api/display/remotertbzone'
import { getRemoteVideoZonesShort } from '@/api/display/remotevideozone'
import { getCampaignsShort } from '@/api/display/campaign'

export default {
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      selectedPublisher: [],
      publisherStatus: 0,
      modal: {
        zoneUrl: '',
        reportsByDate: '',
        reportsBySubid: '',
        reportsByCountry: '',
      },
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          headerTitle: 'Actions',
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Id',
          label: 'Zone ID',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: true,
        },
        {
          key: 'PublisherName',
          label: 'Publisher',
          show: true,
          headerTitle: 'Publisher Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'Campaigns',
          label: 'Campaigns',
          show: true,
          headerTitle: 'Campaigns',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 100px',
        },
        {
          key: 'Remotertbzones',
          label: 'Remote RTB Zones',
          show: true,
          headerTitle: 'Remote RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 150px',
        },
        {
          key: 'Remotevideozones',
          label: 'Remote Video Zones',
          show: true,
          headerTitle: 'Remote Video Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 160px',
        },
        {
          key: 'RequestsDailyLimit',
          label: 'Requests Daily Limit',
          headerTitle: 'Requests Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:160px',
          summed: false,
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          headerTitle: 'Impressions Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'SpentDailyLimit',
          label: 'Spent Daily Limit',
          headerTitle: 'Spent Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatCurrency',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'Bidfloor',
          label: 'Bidfloor',
          headerTitle: 'Bidfloor',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:80px',
        },
        {
          key: 'Margin',
          label: 'Margin',
          show: true,
          headerTitle: 'Margin',
          sortable: true,
          thClass: 'text-center',
          thStyle: 'width:75px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'PublisherRevShare',
          label: 'Rev. Share',
          show: true,
          headerTitle: 'Publisher Revenue Share',
          sortable: true,
          thClass: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'Keywords',
          label: 'Keywords',
          show: false,
          headerTitle: 'Keywords',
          sortable: true,
        },
        {
          key: 'SiteDomain',
          label: 'Site Domain',
          show: false,
          headerTitle: 'Site Domain',
          sortable: true,
        },
        {
          key: 'AppName',
          label: 'App Name',
          show: false,
          headerTitle: 'App Name',
          sortable: true,
        },
        {
          key: 'AppBundle',
          label: 'App Bundle',
          show: false,
          headerTitle: 'App Bundle',
          sortable: true,
        },
        {
          key: 'AppStoreUrl',
          label: 'App Store URL',
          show: false,
          headerTitle: 'App Store URL',
          sortable: true,
        },
        {
          key: 'Tmax',
          label: 'Tmax',
          headerTitle: 'Tmax',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:80px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'AuctionType',
          label: 'Auction Type',
          show: true,
          headerTitle: 'Auction Type',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:130px',
        },
        {
          key: 'Linearity',
          label: 'Linearity',
          show: false,
          sortable: true,
          tdClass: 'text-md-left text-center',
          thStyle: 'width:180px',
        },
        {
          key: 'Placement',
          label: 'Placement',
          show: false,
          sortable: true,
          tdClass: 'text-md-left text-center',
          thStyle: 'width:180px',
        },
        {
          key: 'Secure',
          label: 'Secure',
          show: true,
          headerTitle: 'Secure',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'Width',
          label: 'Width',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:70px',
        },
        {
          key: 'Height',
          label: 'Height',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:70px',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
          headerTitle: 'Status String',
          sortable: true,
        },
      ],
      campaigns: null,
      remotertbzones: null,
      remotevideozones: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      impressionTrackingMethod: ['Impression Pixel', 'Any Available Image'],
    }
  },
  computed: {
    ...mapState(['videozone', 'settings']),
    ...mapGetters('videozone', ['getVideozones', 'getActiveVideozones']),
    showActive: {
      get: function () {
        return this.settings.showActiveVideoZones
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'showActiveVideoZones',
          value,
        })
      },
    },
    showVideozones() {
      return this.showActive ? this.getActiveVideozones : this.getVideozones
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showVideozones.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Remotertbzones' || key === 'Remotevideozones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    selectUrl(e) {
      e.target.select()
      e.stopPropagation()
    },
    copyUrl(link) {
      navigator.clipboard.writeText(link)
    },
    redirectToCreateVideozone() {
      // this.$router.push('/display/videozone/create/' + this.$route.params.id)
      this.$router.push({
        name: 'create-videozone-pubId',
        params: { publisherId: this.$route.params.id },
      }).catch(() => {
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    getPublishers() {
      this.$store.dispatch('videozone/LOAD_VIDEOZONES_FOR_PUBLISHER', { PublisherId: this.selectedPublisher })
    },
    formatLinearity(value) {
      if (value === -1) {
        return 'All'
      } else if (value === 1) {
        return 'Linear'
      } else if (value === 2) {
        return 'Non-Linear'
      }
    },
    formatPlacement(value) {
      if (value === 1) {
        return 'In-Stream'
      } else if (value === 2) {
        return 'In-Banner'
      } else if (value === 3) {
        return 'In-Article'
      } else if (value === 4) {
        return 'In-Feed'
      } else if (value === 5) {
        return 'Interstitial/Slider/Floating'
      }
    },
    getVideozone(id) {
      cloneVideozone(id).then(response => {
        if (response) {
          // this.$router.push('/display/videozone/update/' + response.Videozone.Id)
          this.$router.push({
            name: 'videozone-update',
            params: { id: response.Videozone.Id },
          }).catch(() => {
          })
          this.$notification.success({
            message: 'Video Zone Cloned',
            description: `Video Zone: ${response.Videozone.Name} has been successfully cloned.`,
          })
        }
      })
    },
    copyVideozone(id, videozone) {
      Modal.confirm({
        title: 'Are you sure you want to clone Video Zone?',
        content: h => <div>{status} Clone Video Zone: <strong>{videozone.Name}</strong>?</div>,
        onOk: function () {
          this.getVideozone(id)
        }.bind(this),
      })
    },
    showModal(item) {
      this.$bvModal.show('bv-modal-example')
      this.modal.zoneUrl = item.ZoneUrl
      this.modal.reportsByDate = item.StatsByDate
      this.modal.reportsBySubid = item.ReportsBySubid
      this.modal.reportsByCountry = item.ReportsByCountry
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editVideozone(id) {
      // this.$router.push('/display/videozone/update/' + id)
      this.$router.push({
        name: 'videozone-update',
        params: { id: id },
      }).catch(() => {
      })
    },
    toggleVideozoneStatus(videozone) {
      const status = videozone.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Video Zone status?',
        content: h => <div>{status} Video Zone <strong>{videozone.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = videozone.Status === 0 ? 1 : 0
          this.$store.dispatch('videozone/CHANGE_STATUS', {
            id: videozone.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_FOR_PUBLISHER', { PublisherId: this.$route.params.id })
    getPublishers(this.$route.params.id).then(response => {
      if (response) {
        this.publisherStatus = response.Status
      }
    })
    getRemoteRTBZonesShort()
      .then(res => {
        this.remotertbzones = res
      })
    getRemoteVideoZonesShort()
      .then(res => {
        this.remotevideozones = res
      })
    getCampaignsShort()
      .then(res => {
        this.campaigns = res
      })
  },
}
</script>
<style scoped>
.rtb-modal {
  color: red;
}

.parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parameter-key {
  width: 20%;
  font-weight: 600;
}

.parameter-value {
  width: 80%;
  text-align: left;
}

.parameters-title {
  font-size: 140%;
  font-style: bold;
}

@media only screen and (max-width: 992px) {
  .parameter-value {
    width: 75%;
    text-align: left;
  }
}
</style>
